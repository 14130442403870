$textGrey: #757575;
$textBlack: #161515;
$buttonGreen: #1dc61b;
$fontFamily:'Lato';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

body {
  font-family: $fontFamily;
}

ul,
ol,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}


.admin-pages{
  .button-box {
    width: 100%;
    position: relative;
    padding: 30px 0 20px;
    text-align: center;
  
    .button.button-full-width {
      background-color: black;
      color: white;
      border-radius: 6px;
      border: none;
      text-transform: uppercase;
      height: 36px;
      width: 100%;
      font-size: 13px;
    }
  
    .button.button-default {
      background-color: black;
      color: white;
      border-radius: 6px;
      border: none;
      text-transform: uppercase;
      height: 36px;
      min-width: 156px;
      width: auto;
      font-size: 13px;
    }
  }
  
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 180px;
    height: 100vh;
    background: #f7f7f7;
    box-shadow: 0 0 10px lightgrey;
    z-index: 2;
  
    .logo {
      width: 100%;
      padding: 20px 5px;
      position: relative;
  
      img {
        width: 85%;
        object-fit: contain;
      }
    }
  
    .menu {
      position: relative;
      margin-top: 30px;
      padding: 0 10px;
  
      ul {
        li {
          padding: 0 12px;
          text-align: left;
          margin: 23px 0;
          font-size:15px;
  
          a {
            text-decoration: none;
            color: $textBlack;
            white-space: pre;
          }
          span.badge{
            font-weight: normal;
            font-size: 12px;
            background-color: #434343;
            color: #ffe000;
            display: inline-block;
            width: 35px;
            height: 20px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;
            top: -1px;
            border-radius: 18px;
            margin-left: 1px;
          }
          .submenu{
            li{
              font-size:13px;
              margin:10px 0;
            }
          }
        }
      }
    }
  
    .footer {
      position: fixed;
      margin-top: 30px;
      padding: 0 20px;
      bottom: 30px;
  
      a.log-out {
        padding: 0 21px;
        text-decoration: none;
        color: $textBlack;
        font-size: 15px;
        display: block;
        text-align: left;
      }
    }
  }
  
  .background-wrapper {
    position: relative;
    padding: 50px 0 0;
    text-align: center;
  }
  
  .admin-page {
    position: relative;
    padding-left: 180px;
  
    .page-content {
      padding: 13px 13px 30px;
      .page-title {
        margin: 20px;
        text-indent: 10px;
        font-weight: 700;
        font-size: 22px;
      }
      .admin-dashboard{
        position: relative;
        box-shadow: 0 0 10px lightgrey;
        border-radius: 10px;
        margin-top: 30px;
        min-width: 800px;
        min-height: 200px;
        padding: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .info-container{
      position: relative;
      margin: 10px 0 20px;
      padding: 13px;
      font-size: 14px;
      .content-row{
        padding:10px 0;
      }
      .subject-label{
        font-weight: 600;
        width: 200px;
        padding: 5px 0px;
      }
      span.attached-file{
        cursor: pointer;
        border-bottom: 2px solid #279ed4;
      }
    }
    .client-request-content{
      .requester-contacts{
        table{
          width:400px;
        }
      }
    }

    .form {
      position: relative;
      padding: 50px 30px;
      min-width: 850px;
      border: 1px solid #f2f2f2;
      overflow: hidden;
      .form-title {
        margin: 0 0 30px;
        font-weight: bold;
        display: flex;
        align-items: center;
      }
      .loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      label.group-label {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 600;
      }
      .input-box {
        width: 100%;
        position: relative;
        text-align: left;
        padding: 14px 5px 0px;
        &.box-inline{
          display: inline-block;
          width:auto;
        }
        &[data-type='checkbox']{
          padding-top:28px;
        }
        label {
          font-size: 12px;
          color: $textGrey;
          max-height: 19px;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        
        input,
        select,
        textarea {
          width: 100%;
          border: 1px solid lightgrey;
          padding: 3px 3px;
          color: $textGrey;
          margin: 2px 0;
          outline: none;
          border-radius: 7px;
          font-size: 12px;
          font-family: inherit;
          min-height: 30px;
    
          &[type='checkbox'] {
            height: 18px;
            width: 18px;
            position: relative;
            top: 5px;
            left: 3px;
            margin-right: 7px;
          }

          &[type='file'][name='images'] {
            display: flex;
            justify-content: center;
            box-shadow: 0 0 5px lightgrey;
            border: none;
            align-items: center;
            width: 100px;
            height: 100px;
            position: relative;
            top: -3px;
            border-radius: 6px;
            &::file-selector-button {
              background-color: black;
              color: white;
              display: none;
            }
            &::after {
              content: '+ Add';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              font-size: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: lightgrey;
              background-color: white;
              cursor: pointer;
            }
          }

          &:disabled{
            background-color:#f1f1f1;

            &.fixed{
              background-color: #d5d5d5;
              color: black;
              font-size: 17px;
              text-align: center;
              padding: 2px;
              font-weight: bold;
            }
          }



          option{
            &:disabled{
              background-color: #e2e2e2;
              color: black;
            }
          }
        }
        .zoom-image {
          display: none;
          position: fixed;
          background: rgba(0, 0, 0, 0.68);
          width: 100vw;
          height: 100vh;
          z-index: 1;
          left: 0;
          top: 0;
          .close-image{
            position: absolute;
            top: 0;
            right: 0;
            width: 70px;
            height: 70px;
            line-height: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 76px;
            font-weight: 100;
            cursor: pointer;
            color: white;
            text-shadow: 4px 2px 5px black;
            transition: color 0.5s ease;
            &:hover{
              color:#d5d5d5;
            }
          }
          .slide-button{
            position: absolute;
            top: 40vh;
            height: 20vh;
              display: flex;
              align-items: center;
              color: white;
              font-size: 93px;
            padding: 0 37px;
            &.previous{          
              left: 0;
            }
            &.next{
              right:0;
            }
          }
          img{
            width: 100%;
          height: 100%;
          object-fit: contain;
          padding: 5%;
          }
        }
        .input-images {
          position: relative;
          display: flex;
          flex-direction: row;
          width: 100%;
          overflow-y: hidden;
          padding: 20px 3px;
          min-height: 150px;
    
          .input-image {
            position: relative;
            display: flex;
            height: 100px;
            width: 100px;
            min-width: 100px;
            cursor: pointer;
            box-shadow: 0 0 5px lightgrey;
            padding: 10px;
            margin: 2px;
            border-radius: 5px;
            cursor: pointer;
            .remove {
              position: absolute;
              top: 0;
              right: 0;
              height: 0;
              width: 0;
              background-color: rgba(0, 0, 0, 0.4);
              color: white;
              font-size: 15px;
              display: none;
            }
            &:hover {
              .remove {
                display: flex;
                justify-content: center;
                align-items: center;
                .remove-button {
                  color: white;
                  border: 1px solid #ffffff;
                  background-color: rgba(0, 0, 0, 0.68);
                  border-radius: 3px;
                  padding: 3px;
                  width: auto;
                  position: absolute;
                  top: -11px;
                  right: -11px;
                  z-index: 1;
                  line-height: 0.6;
                  font-size: 23px;
                }
              }
            }
            img {
              width: 100%;
              object-fit: contain;
            }
          }
        }
      }
      .action-buttons {
        position: relative;
        text-align: right;
        button {
          margin: 0 5px;
          padding: 4px 10px;
          font-size: 15px;
          border: none;
          border-radius: 4px;
          box-shadow: 0 0 5px #bcb2b2;
        }
        .back-button {
          color: black;
          background-color: whitesmoke;
          box-shadow: none;
        }
    
        .delete-button {
          color: white;
          background-color: red;
        }
        .approve-button{
          color: white;
          background-color: #3647bb;
        }
        .save-button {
          color: white;
          background-color: $buttonGreen;
        }
    
        .draft-button {
          color: red;
          background-color: whitesmoke;
        }
    
        .publish-button{
          color:white;
          background-color:#3647bb;
        }
      }
    }
    #sub-items,
    #size-price {
      position: relative;
      background-color: #f1f1f1;
      padding: 10px 0 39px 27px;
      margin-bottom: 60px;
      .sub-items-row{
        position:relative;
        .item-number {
          position: absolute;
          display: inline-block;
          display: inline-block;
          justify-content: center;
          align-items: center;
          width: auto;
          height: auto;
          left: -17px;
          top: 38px;
          font-size: 12px;
          font-weight: bold;
        }
        .row-delete {
          width: 16px;
          position: relative;
          cursor: pointer;
          height: 100%;
          top: 25px;
          left: 3px;
          opacity: 0.4;
        }
  
        .row-info{
          width: 20px;
          height: 20px;
          background-color: red;
          position: relative;
          cursor: pointer;
          display: inline-block;
          line-height: 1.3;
          top: 24px;
          opacity: 0.4;
          border-radius: 50%;
          color: white;
          text-align: center;
        }
      }

      button.add-size,
      button.add-item {
        position: absolute;
        bottom: -25px;
        right: -10px;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        border: none;
        font-size: 12px;
        color: white;
        background-color: $buttonGreen;
        line-height: 1;
        font-weight: 600;
      }

      .input-box{
        input,
          select,
          textarea,
          button {
            padding: 4px 1px;
            font-size: 11px;
          }
          label {
            font-size: 12px;
            min-height: auto;
          }
      }
    }
    .pagelist {
      position: relative;
      margin-top: 30px;
  
      section {
        margin: 30px 0;
        padding: 20px;
        box-shadow: 6px 5px 20px 0px lightgrey;
        background-color: #f1f1f1;
        border: 1px solid #e8e8e8;
        border-radius: 20px;
  
        &.admin-pages {
          position: relative;
        }
  
        &.client-pages {
          position: relative;
        }
  
        p {
          font-weight: bold;
          text-align: center;
          padding: 0 0 10px;
        }
  
        ul {
          li {
            text-align: center;
            margin: 5px 0;
            color: #161515;
            background-color: white;
            cursor: pointer;
            transition: all 0.3s linear;
            &:hover {
              background-color: #18a121;
              color: white;
            }
  
            a {
              text-decoration: none;
              color: inherit;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              height: 100%;
              padding: 5px;
              box-sizing: border-box;
              .page-name {
                width: 200px;
                font-weight: bold;
              }
              .page-definition {
                width: calc(100% - 200px);
                text-align: left;
                font-size: 13px;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
    #status-row{
      margin:30px 0;
      background-color: #edebeb;
      padding: 0 24px 20px;
      border-radius: 10px;
    }
  }
}

.alert-box{
    position: absolute;
    right: -400px;
    font-size: 14px;
    top: 43px;
    padding: 12px 34px;
    
    color: white;
    border: none;
    box-shadow: 0 0 10px lightgrey;
    font-weight: 400;
    overflow: hidden;
    transition: all 0.4s ease;
    border-radius: 10px;
    &.show{
      right:30px;
      transition: all 0.4s ease;
    }

    &.error{
      background-color:red;
    }

    &.success{
      background: #5ac800;
    }
}

.status-label{
    font-size: calc(100% - 7px);
    border-radius: 17px;
    width: auto;
    color: white;
    display: inline-block;
    white-space: pre;
    padding: 3px 23px;
    font-weight: 400;
    text-transform: lowercase;
    text-indent: 0;
}


