div.admin-table {
  position: relative;
  box-shadow: 0 0 10px lightgrey;
  border: 2px solid #e3e3e3;
  border-radius: 19px;
  margin-top: 30px;
  min-width: 800px;
  min-height: 200px;
  padding: 13px;
  .actions-header{
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #f3f3f3;
    padding: 20px;
    a.add-new {
      position: relative;
      width:auto;
      font-size: 12px;
      line-height: 1;
      background-color: #2aaf34;
      font-weight: bold;
      color: white;
      border: none;
      font-family: inherit;
      display:inline-block;
      padding: 9px 15px;
    }
  }

  .loading {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    min-height: 0px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }

  table.admin-data {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-family: inherit;

    tr {
      td,
      th {
        vertical-align: middle;
        text-align: center;
        padding: 9px 0 9px 9px;
        width: auto;
        min-width: 25px;
        max-width: 150px;
        .column-icon{
          font-size: 18px;
          color: #b0b0b0;
          margin-left: 10px;
          font-weight: 100;
          cursor: pointer;
        }
        &.actions-list {
          a {
            height: 30px;
            width: 30px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
            margin:0 3px;
            &:hover{
              background:#e7e7e7;
            }
            img {
              height: 22px;
              width: 22px;
              object-fit: contain;
            }
          }
        }
      }
    }
    tbody {
      font-weight: normal;
      tr {
        cursor: pointer;
        &:hover {
          background-color: #f2f2f2;
        }
        &:nth-child(odd){
          background-color: #f7f7f7;
        }
      }
      td {
        img {
          height: 36px;
          width: 36px;
          object-fit:contain;
        }
        a.link{
          color: blue;
          border-bottom: 1px solid;
        }
        &.label{
          span{
            font-size: 12px;
            border-radius: 17px;
            width: auto;
            color: white;
            display: inline-block;
            white-space: pre;
            padding: 2px 6px;
            font-weight: 400;
            text-transform: lowercase;
            &.label-blue{
              background-color: #42acc4;
            }
            &.label-green{
              background-color: #42c442;
            }
            &.label-red{
              background-color: red;
            }
          }
        }
        &.actions-list{
          text-align: left;
          .action-icon{
            height: 17px;
            width: 30px;
          }
        }
      }
    }
    thead{
        font-weight: 600;
      th{
        font-size:14px;
      }
      th.table-sub-header{
        label.table-header-label{
          padding: 3px 11px;
          border-radius: 13px;
          color: white;
          margin: 0 6px;
          font-size: 12px;
          font-weight: normal;
          cursor:pointer;
          span.badge{
            background-color:white;
            border-radius: 13px;
            position: relative;
            top: 0px;
            left: 4px;
            width: 24px;
            font-size: 10px;
          }
        }
      }

    }
  }

  .no-data{
    text-align: center;
    padding: 20px 0;
    font-size: 15px;
    font-weight: bold;
    background-color: whitesmoke;
  }

  .table-footer{
    padding:19px 0 10px;
    .pagination-wrapper{
      display: flex;
      justify-content: space-between;
      .page-size{
        label{
          font-size:13px;
        }
        select{
          font-size:14px;
          margin-left: 10px;
        }
      }
      .pages{
        .info-button{
          display: inline-block;
          border-radius: 3px;
          box-sizing: content-box;
          width: auto;
          height: 20px;
          border: 1px solid lightgrey;
          font-size: 13px;
          color: black;
          text-align: center;
          margin-right: 12px;
          padding: 0 10px;
          position: relative;
          top: -2px;
        }
        .arrow-button{
          position:relative;
          display: inline-block;
          border-radius: 50%;
          box-sizing: content-box;
          width: 32px;
          height: 32px;
          font-size: 22px;
          font-family: monospace;
          color: black;
          text-align: center;
          line-height: 0.2;
          margin-left: -4px;
          border: none;
          color: #42acc4;
          transition: all 0.2s ease;
          background: white;
          z-index: 0;
          &:hover{
            background-color:#42acc4;
            color:white;
            z-index: 2;
          }
        }
      }

    }
  }
}
