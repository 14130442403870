$textGrey: #757575;
$textBlack: #161515;
$logoYellow: #ffce00;
$mainYellow: #ffa901;
$orange: #eb8954;
$fontFamily: 'Lato';
$headersFontFamily: 'Poppins';
$contentMaxWidth: 1243px;

div,body,ul,ol,li,p,h1,h2,h3,h4,h5,h6,button,header,footer,main,aside,hr {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

@mixin section-main-header{
  font-family: $headersFontFamily;
  color: #2f302c;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 0 10px 0;
}

@mixin section-sub-header{
  font-family: $headersFontFamily;
  padding: 5px 0;
  font-size: 14px;
  color: $textGrey;
  text-transform: uppercase;
  font-weight: 300;
}

@mixin form-main-header{
  font-family: $headersFontFamily;
  color: #2f302c;
  font-size: 23px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 0 10px 0;
}

@mixin form-sub-header{
  font-family: $headersFontFamily;
  padding: 5px 0;
  font-size: 13px;
  color: $textGrey;
  font-weight: 300;
}

body {
  position: relative;
  font-family: $fontFamily;

  .page {
    height: auto;
    min-height: 100vh;
    height: auto;
    padding: 60px 0;
    text-align: center;
    transition: top 0.2s ease;

    .page-content{
      position:relative;
      padding:100px;
      
      &.text-content{
        .page-header{
          .main{
            padding: 10px 0 50px 0;
          }
        }
        .paragraph{
          color: $textGrey;
          text-align: left;
          text-wrap: pretty;
          text-indent: 20px;
          font-size: 15px;
          line-height: 1.8;
          font-weight: 300;
          p{
            padding-top:30px;
            overflow-wrap: break-word;
          }
        }
      }
    }

    .page-header{
      margin-bottom:30px;
      .main{
        @include section-main-header;
      }
      .sub{
        @include section-sub-header
      }
    }

    
    p.breadcrumb {
      display: inline-block;
      font-size: 13px;
      border-radius: 4px;
      line-height: 1.4;
      padding: 2px 12px;
      color: black;
      font-weight: bold;
      a {
        color: black;
        font-weight: 400;
      }
    }
    .container {
      max-width: 1000px;
      .logo {
        position: relative;
        width: 319px;
        margin: 33px auto;
        img {
          width: 100%;
        }
      }
    }  
  
    &.home-page {
      .slider {
        position: relative;
        height: auto;
        overflow: hidden;
        .slider-item {
          img {
            width: 100%;
          }
        }
        .slick-dots {
          bottom: -19px;
          ul {
            li {
              button {
                color: grey;
                width: 11px;
                height: 11px;
                background-color: grey;
                border-radius: 50%;
                &::before {
                  content: '';
                }
              }
  
              &.slick-active {
                button {
                  background-color: lightgrey;
                  &::before {
                    content: '';
                  }
                }
              }
            }
          }
        }
      }
      .categories {
        display: flex;
        align-items: center;
        justify-content: center;
        .category-card-container {
          padding:8px;
          .category-card {
            position: relative;
            height: 300px;
            width: 100%;
            box-sizing: border-box;
            box-shadow: 1px 1px 4px #6b6b6b;
            transition: all 0.2s ease;
            a {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
            }
            img.category-image {
              height: 100%;
              width: 100%;
              object-fit: cover;
              background-color: lightgrey;
              filter: blur(1px) brightness(0.8);
            }
            .category-name {
              position: absolute;
              left: 0;
              bottom: 0;
              background-color: rgb(255 255 255 / 95%);
              width: 100%;
              height: 55px;
              display: flex;
              align-items: center;
              h3 {
                font-size: 15px;
                font-family: $headersFontFamily;
                padding: 10px;
                font-weight: 700;
                color: #3c4242;
                text-align: left;
                display: flex;
                justify-content: center;
                align-items: center;
  
                &:after {
                  content: '>>';
                  letter-spacing: -8px;
                  font-size: 27px;
                  position: relative;
                  top: 0px;
                  color: #939393;
                  font-weight: 600;
                  transition: all 0.2s ease;
                }
              }
            }
            &:hover {
              transform: scale(1.05);
              img.category-image {
                filter: blur(0) brightness(1);
              }
              h3 {
                &:after {
                  content: '>>';
                  letter-spacing: -8px;
                  font-size: 27px;
                  position: relative;
                  top: 0px;
                  color: $mainYellow;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
      .partners {
        .partner-card {
          box-sizing: border-box;
          background-color: white;
          margin: 11px;
          height: 100%;
          a {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          .partner-image {
            width: 100%;
            max-width: 130px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  
    &.items-page {
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction:row;
      @media screen and (max-width:992px){
        flex-direction:column;
      }
      .banner {
        display: none;
        height: 151px;
        position: relative;
        overflow: hidden;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          animation: 0.5s infinite alternate toggle;
          filter: saturate(0);
        }
  
        @keyframes toggle {
          0% {
            filter: saturate(1);
          }
  
          50% {
            filter: saturate(0);
          }
        }
      }
      .categories-sidebar-main {
        position: sticky;
        margin-top: 69px;
        width: 235px;
        background-color: white;
        padding: 20px 0;
        text-align: left;
        top: 80px;
        left: 20px;
        height: auto;
        display: block;
        z-index: 1;
  
        h4 {
          font-size: 18px;
          font-weight: bold;
          padding: 20px 0;
          text-indent: 10px;
        }
  
        ul.main-category {
          li {
            position: relative;
            user-select: none;
            font-size: 0;
            padding: 8px 5px 7px 16px;
            span {
              position: relative;
              cursor: pointer;
              font-weight: 400;
              font-size: 12px;
              display: flex;
              margin-left: 0;
              justify-content: space-between;
              align-items: center;
              color: $textBlack;
              span.has-sub {
                background-image: url('../../public/Icons/right-arrow.svg');
                background-position: center;
                background-size: cover;
                height: 14px;
                display: inline-block;
                width: 14px;
                background-color: transparent;
                -webkit-user-select: none;
                user-select: none;
                margin-left: 10px;
              }
              &.all {
                font-weight: bold;
              }
            }
  
            &:hover {
              background-color: whitesmoke;
            }
            ul.sub-categories {
              display: none;
              position: absolute;
              right: -100%;
              top: 0;
              background: white;
              height: auto;
              width: 100%;
              font-size: 13px;
              border-radius: 0px 5px 5px 5px;
              box-shadow: 4px 2px 13px lightgrey;
              overflow: hidden;
              li {
                position: relative;
                display: block;
                font-size: 13px;
                color: white;
                a {
                  font-size: 13px;
                }
              }
            }
  
            &:hover ul.sub-categories {
              display: block;
            }
          }
        }
  
        &.mobile {
          position: absolute;
          top: 57px;
          left: 19px;
          padding: 0px;
          width: auto;
          max-width: 240px;
          box-shadow: 0 0 10px lightgrey;
          border-radius: 7px;
          display: none;
          ul.main-category {
            li {
              padding: 0px;
              span {
                font-size: 12px;
                padding: 3px 5px;
              }
              ul.sub-categories {
                display: none;
                position: relative;
                left: 0;
                background-color: whitesmoke;
                box-shadow: none;
                li {
                  position: relative;
                  display: block;
                  font-size: 13px;
                  color: white;
                  padding-left: 20px;
                  &:hover {
                    background-color: lightgrey;
                  }
                }
              }
              &.open {
                span {
                  span.has-sub {
                    transform: rotate(90deg);
                    height: 11px;
                  }
                }
                ul.sub-categories {
                  display: block;
                }
              }
            }
          }
  
          &.open {
            display: block;
          }
        }
      }
  
      img.categories-button {
        position: absolute;
        left: 0px;
        top: -3px;
        width: 36px;
        height: 36px;
        padding: 1px;
        box-sizing: content-box;
        cursor: pointer;
  
        &.open {
          background-color: #eae9e9;
          border-radius: 4px;
        }
  
        @media only screen and (min-width: 768px) {
          display: none;
        }
      }
  
      .items-list {
        position: relative;
        padding: 0 0px 0 40px;
        width: 100%;
        top: 20px;
        padding:20px 22px;
        .loading{
          align-items:start;
        }
        .search-items {
          position: relative;
          margin: 10px 0 20px;
          
          input {
            width: 100%;
            border: none;
            border-bottom: 1px solid lightgrey;
            padding: 8px 0;
            background-color: transparent;
            text-indent: 4px;
            outline: none;
            text-indent: 44px;
            color: $textBlack;
            font-weight: 300;
            font-size: 14px;
  
            @media only screen and (max-width: 768px) {
              text-indent: 60px;
            }
          }
  
          button.search-button {
            border: none;
            background-color: transparent;
            position: absolute;
            left: 2px;
            top: 2px;
            width: 25px;
            height: 25px;
            padding: 4px;
            box-sizing: content-box;
            border-radius: 4px;
            font-size: 0;
  
            svg {
              width: 100%;
              height: 100%;
            }
            &:focus {
              outline: 2px solid lightgrey;
            }
          }
  
          .clear-results-button {
            position: absolute;
            right: 0;
            top: 7px;
            font-size: 13px;
            padding: 4px 19px;
            border: none;
            border-radius: 6px;
            background-color: #343434;
            color: white;
          }
        }
  
        @media only screen and (max-width: 768px) {
          position: relative;
          padding: 0 13px;
          left: 0;
          width: 100%;
          button.search-button {
            display: none;
          }
        }
      }
  
      .load-more {
        padding: 0;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color:#373737;
        .sort-by {
          position: relative;
          padding: 6px 13px 6px 10px;
          font-size: 12px;
          background-color: #f6f6f6;
          cursor: pointer;
          > *:not(.options) {
            pointer-events: none;
          }
          .chevron-icon {
            background-image: url('../../public/Icons/right-arrow.svg');
            background-position: center;
            background-size: cover;
            transform: rotate(90deg);
            height: 11px;
            display: inline-block;
            width: 11px;
            background-color: transparent;
            -webkit-user-select: none;
            user-select: none;
            margin-left: 8px;
          }
          .options {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1;
            width: 100%;
            background-color: #f6f6f6;
            height: 0;
            overflow: hidden;
            div {
              padding: 3px;
              border-bottom: 0.5px solid lightgrey;
              &:first-child {
                border-top: 0.5px solid lightgrey;
              }
              &:hover {
                background-color: lightgrey;
              }
              user-select: none;
            }
          }
          &.open {
            .options {
              height: auto;
            }
          }
        }
        .result-info {
          font-size: 13px;
          font-weight: 600;
          padding: 6px 13px 6px 10px;
        }
        #load-more-button {
          background-color: black;
          color: white;
          padding: 5px 15px;
          font-size: 13px;
          border: none;
          padding: 5px 12px;
          line-height: 1.6;
          &.disabled {
            touch-action: none;
            background-color: #c3c3c3;
            color: #707070;
          }
        }
        @media only screen and (max-width: 768px) {
          .result-info{
            font-size:11px;
            padding: 0;
          }
          .sort-by{
            font-size:11px;
          }
        }
      }
    }
  
    .item-container {
      padding: 18px 10px 5px;
      box-sizing: border-box;
      &:hover {
        background-color: #f9f9f9;
        border-radius: 14px;
        border: 1px solid lightgrey;
      }
      .item-card {
        position: relative;
        width: 100%;
        margin: 0px 0 22px;
        padding: 31px 10px 5px;
        transition: background-color 0.3s ease;
  
        .item-number {
          background-color: black;
          border-radius: 4px;
          padding: 1px 9px;
          line-height: 1.2;
          white-space: inherit;
          font-size: 12px;
          color: white;
          display: block;
          width: auto;
          text-align: center;
          position: absolute;
          top: 5px;
          right: 5px;
          margin: 4px;
        }
        .item-card-link {
          display: inline-block;
          height: 100%;
          width: 90%;
        }
  
        .item-image {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px 0;
          height: 110px;
          width: 100%;
          position: relative;
          overflow: hidden;
          border-radius: 13px;
  
          img {
            width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }
  
        .item-name {
          color: $textBlack;
          display: inline-block;
          height: 46px;
          width: 100%;
          font-size: 11px;
          padding: 0 5px;
          text-align: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          font-weight: 500;
          overflow: hidden;
          text-transform: capitalize;
        }
  
        .item-price {
          color: $textBlack;
          font-size: 13px;
          padding: 20px 0;
          font-weight: bold;
        }
  
        .add-item-to-cart {
          position: relative;
          font-size: 14px;
          background-color: $mainYellow;
          color: black;
          padding: 4px 0;
          margin: 24px 20px 3px;
          cursor: pointer;
  
          &::before {
            position: relative;
            content: '';
            top: 3px;
            left: 0;
            display: inline-block;
            height: 20px;
            width: 20px;
            margin-right: 7px;
            background-size: contain;
            transform: rotateY(180deg);
          }
        }
      }
    }
  
    &.item-page {
      .item-content {
        background-color: white;
        position: relative;
        text-align: left;
        padding: 60px 0;
        max-width: 1200px;
        margin: 0 auto;
        .item-page-card {
          padding-top: 40px;
        }
        .item-image {
          position: relative;
          text-align: center;
          display: flex;
          flex-direction: column;
  
          img {
            width: 100%;
            object-fit: contain;
            padding: 11px;
            border: 0.3px solid #ededed;
          }
          .image-list {
            display: flex;
            overflow-x: auto;
            padding: 10px 0;
            .image-list-item {
              height: 78px;
              width: 78px;
              background-color: white;
              min-height: inherit;
              object-fit: contain;
              border: 1px solid white;
              padding: 6px;
              box-shadow: 0px 0px 5px 0px lightgrey;
              cursor: pointer;
              margin: 3px;
              &.selected {
                border: 3px solid #ffa901;
              }
            }
            &::-webkit-scrollbar {
              height: 5px;
            }
  
            /* Track */
            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }
  
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #a8a8a8;
            }
  
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }
          }
        }
  
        .item-header {
          padding: 0px 0 20px;
          .name {
            padding: 0 0 4px;
            display: flex;
            font-weight: 700;
            text-align: left;
            font-size: 24px;
            font-family: 'Poppins';
          }
        }
        .item-body {
          display: flex;
          justify-content: space-between;
          .item-price {
            font-size: 22px;
            color: $mainYellow;
            text-wrap: nowrap;
            padding: 0 30px 0 0;
            min-width: 130px;
          }
          .item-description {
            color: $textGrey;
            font-size: 12px;
            font-family: 'Poppins';
            margin: 12px 0;
            p {
              white-space: pre-line;
            }
          }
  
          .item-details {
            font-family: 'Poppins';
            flex-grow: 1;
            .item-properties {
              display: flex;
              justify-content: start;
              .item-property {
                width: 50%;
              }
            }
            .value-container {
              position: relative;
              cursor: pointer;
              display: inline-block;
              user-select: none;
              .parameter-value {
                border: 1px solid $mainYellow;
                cursor: pointer;
                font-size: 10px;
                padding: 2px;
                margin: 3px 0;
                font-weight: 400;
                display: inline-block;
                -webkit-user-select: none;
                user-select: none;
                width: 100%;
                text-align: center;
                &.selected {
                  background-color: #bdbdbd;
                  font-size: 10px;
                  font-weight: 500;
                }
              }
            }
  
            .item-sizes,
            .item-substrates {
              label {
                display: block;
                font-size: 13px;
                font-weight: 500;
                span {
                  font-size: 11px;
                  color: $textGrey;
                }
              }
              select {
                border: none;
                height: 36px;
                min-width: 156px;
                width: auto;
                font-size: 16px;
                color: $textGrey;
                border: 1px solid $textGrey;
                text-align: center;
                outline: none;
                height: 36px;
              }
            }
          }
        }
  
        .item-tags {
          .item-tag {
            display: inline-block;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.3px;
            border-radius: 4px;
            line-height: 1.4;
            padding: 2px 6px;
            margin-right: 5px;
            /* background-color: #6d6d6d; */
            background: linear-gradient(45deg, #2757c0, #0353da);
            color: white;
  
            &.item-number {
              background: none;
              background-color: #b2b2b2;
            }
          }
        }
  
        .checkout {
          display: flex;
          justify-content: space-between;
          .quantity {
            width: 30%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            border: 2px solid $mainYellow;
            border-radius: 7px;
            overflow: hidden;
            .buttons {
              cursor: pointer;
              -webkit-user-select: none;
              user-select: none;
              width: 33.3%;
              text-align: center;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              &:hover{
                background-color:#ffe5b1;
              }
            }
          }
          .add-to-cart {
            margin: 0 10px;
            flex-grow: 1;
            .add-item-to-cart {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 32px;
              padding: 4px 20px;
              background: $mainYellow;
              color: black;
              font-size: 12px;
              text-align: center;
              line-height: 1;
              border-radius: 5px;
              cursor: pointer;
  
              &::before {
                content: '';
                position: relative;
                top: 0px;
                left: -2px;
                display: inline-block;
                height: 20px;
                width: 20px;
                margin-right: 7px;
                background-image: url('../../public/Icons/cart.svg');
                background-size: contain;
                background-repeat: no-repeat;
                color: black;
                transform: rotateY(180deg);
              }
            }
          }
          @media only screen and (max-width: 500px) {
            align-items: center;
            flex-direction: column;
          }
        }
      }
      .quote-request-banner {
        padding: 20px 0;
        box-shadow: -8px -57px 168px #e0e0e0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        border-top: 5px dashed #e8e8e8;
        span {
          font-size: 12px;
          color: $textGrey;
        }
        .request-button {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 28px;
          max-width: 200px;
          background: #3d3d3d;
          color: white;
          font-size: 11px;
          text-align: center;
          line-height: 1;
          border: none;
          cursor: pointer;
          span {
            span.arrow {
              position: relative;
              left: 5px;
              top: -2px;
              border-left: 2px solid white;
              border-bottom: 2px solid white;
              transform: rotate(315deg);
              display: inline-block;
              width: 0.8em;
              height: 0.8em;
            }
          }
        }
      }
  
      .contact-form {
        padding: 20px 20px 40px;
        border-radius: 0 0 26px 26px;
        box-shadow: 0px 7px 8px #f4f4f4;
        background-color: white;
        .form {
          .input-box {
            padding: 10px 8px 0px;
          }
        }
      }
      @media only screen and (max-width: 500px) {
        .item-content {
          padding: 30px 10px;
        }
      }
    }
  
    &.contact-page {
      form.form {
        margin: 0 auto;
        .input-box {
          padding: 0;
          input:not([type='file']),
          textarea {
            padding: 24px 3px 5px;
            margin: 2px 0 0;
            font-size: 17px;
            width: 100%;
            border: 1px solid lightgrey;
            color: #4a4a4a;
            outline: none;
            font-weight: 300;
            text-indent: 6px;
            font-family: inherit;
            border-radius: 4px;
          }
          select {
            padding: 17px 3px;
            border: 1px solid lightgrey;
            border-radius: 4px;
          }
          label {
            position: absolute;
            padding: 10px 10px 0;
            font-family: Poppins;
            font-weight: 300;
            font-size: 13px;
          }
        }
        .button-box {
          text-align: left;
        }
      }
      .contact-details {
        position: relative;
        padding: 0 0 0 43px;
        .contact-info {
          padding: 10px 20px;
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;
  
          .icon {
            text-align: center;
            margin-right: 16px;
            img {
              width: 23px;
              object-fit: contain;
            }
          }
  
          .info {
            text-align: justify;
            padding: 0 10px;
            font-size: 14px;
            color: #757575;
            a {
              color: inherit;
            }
          }
        }
      }
  
      .map {
        iframe {
          width: 100%;
          margin-top: 20px;
        }
        img {
          object-fit: contain;
          width: 63%;
        }
      }
    }
  
    &.check-out-page {
      .container {
        margin-top: 20px;
      }
      h1 {
        text-transform: uppercase;
        text-align: center;
        text-indent: 30px;
        padding: 20px 0;
        font-size: 20px;
        font-weight: 800;
      }
      p.subheader{
        font-size:11px;
        font-weight: 300;
        text-transform: uppercase;
      }
  
      .steps{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .check-out-items {
        color: #b9b9b9;
        padding: 0px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: poppins;
        font-weight: 300;
        font-size: 12px;
        a{
          font-weight: 400;
          color:black;
        }
      }
      .check-out-item {
        position: relative;
        font-size: 0;
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        text-align: left;
  
        img {
          width: 100%;
          object-fit: contain;
          position: relative;
          display: block;
          padding: 5px;
          max-height: 160px;
        }
        .item-name {
          width: 100%;
          display: block;
          font-size: 15px;
          white-space: nowrap;
          position: relative;
          font-family: 'Poppins';
          a {
            line-height: 1;
            text-wrap: pretty;
            display: block;
            text-overflow: ellipsis;
            height: 100%;
            padding: 3px 32px 3px 0;
            font-weight: 500;
            text-wrap: nowrap;
            overflow: hidden;
          }
        }
  
        .item-stars {
          margin: 5px 0;
        }
        .item-details {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
          .item-parameters {
            margin: 10px 0;
            .item-parameter {
              font-size: 13px;
              white-space: nowrap;
              position: relative;
              span.parameter-name {
                font-weight: 500;
              }
            }
          }
  
          .item-count {
            color: $textBlack;
            font-weight: bold;
            height: 100%;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            align-self: flex-end;
            background-color: whitesmoke;
            .count {
              display: inline-block;
              min-width: 22px;
              text-align: center;
              position: relative;
              font-size: 14px;
              padding: 0 4px;
              text-wrap: nowrap;
              span.text {
                color: #a7a7a7;
                font-size: 12px;
                font-weight: 500;
              }
            }
            button {
              color: $textBlack;
              font-size: 17px;
              height: 20px;
              width: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: bold;
              border: none;
              background-color: transparent;
              &:hover {
                background-color: lightgrey;
              }
            }
          }
        }
        .item-price {
          position: relative;
          font-size: 14px;
          color: $textBlack;
          display: block;
          text-align: right;
          padding: 5px 0;
          font-weight: bold;
        }
  
        span.remove-item {
          display: block;
          text-align: center;
          button {
            border: none;
            position: absolute;
            top: 0;
            right: 0;
            background: transparent;
            font-size: 44px;
            line-height: 0.5;
            color: lightgrey;
            font-weight: 200;
            &:hover{
              color:grey;
            }
          }
        }
  
        &.disabled {
          .price-is-not-active {
            position: relative;
            display: block;
            font-size: 11px;
            color: #f57878;
            text-align: right;
            padding: 0px 0 20px;
          }
  
          .main-content {
            opacity: 0.4;
            font-weight: 400;
          }
        }
        @media screen and (max-width: 500px) {
          .item-name {
            width: 100%;
            display: block;
            font-size: 12px;
            a {
              line-height: 1;
              text-wrap: pretty;
              display: block;
              text-overflow: ellipsis;
              height: 100%;
              font-weight: 500;
              overflow: hidden;
            }
          }
        }
      }
  
      .order-form {
        padding: 13px;
        position: relative;
        overflow: hidden;
        font-family: 'Poppins';
        border-radius: 17px;
        .sending {
          position: absolute;
          left: 0;
          top: 0;
          background-color: black;
          opacity: 0.6;
          height: 100%;
          width: 100%;
          z-index: 1;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .order-checkout-header {
          text-align: left;
          font-family: Poppins;
          font-size: 16px;
          text-transform: uppercase;
          font-weight: 800;
        }
        hr{
          
         margin:5px 0 1rem;
        }
        .hst {
          font-size: 13px;
        }
        .grand-total {
          font-weight: 600;
          font-size: 15px;
        }
        .sub-total {
          padding: 7px 0 10px;
          font-size: 13px;
        }
        .discount{
          position:relative;
          font-size:11px;
          border-radius: 5px;
          overflow: hidden;
          margin:10px 0;
          border:1px solid lightgrey;
          input[type="text"]{
            width:100%;
            padding:5px 40px 2px 7px;
            border:none;
            outline:none;
          }
          button{
            position:absolute;
            right:0;
            top:0;
            padding:0 10px;
            border:none;
            background-color:black;
            color:white;
            height:100%;
          }
        }
        .discount-rate{
          padding: 10px 0 0;
          font-size: 12px;
        }
        .conclusion {
          text-align: left;
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          .bold-label {
            font-size: 13px;
          }
        }
  
        .ask-billing {
          display: flex;
          font-size: 13px;
          text-align: left;
          align-items: center;
          margin: 18px 0 10px;
          line-height: 1.1;
          input {
            position: relative;
            top: -1px;
          }
          label {
            font-size: 10px;
            margin-left: 4px;
            color: $textGrey;
          }
        }
  
        form {
          margin-top:7px;
          .stripe-button {
            display: flex;
            flex-direction: column;
            align-items: center;
  
            .supported-payments {
              width: 65%;
              max-width: 250px;
            }
            @media screen and (max-width: 991px) {
              flex-direction: row;
              justify-content: space-between;
              .button-box {
                width: 50%;
              }
              .supported-payments {
                width: 50%;
                max-width: auto;
                padding: 0 0px 0 20px;
              }
            }
          }
        }
      }
    }
  
    &.payment-result-page {
      background-color: whitesmoke;
      top: 0;
  
      img {
        width: 100px;
      }
      p.expression {
        font-size: 20px;
        font-weight: 400;
        padding: 20px 0;
        &.success {
          color: #33ee9b;
        }
        &.fail {
          color: #ff5656;
        }
      }
      p.description {
        font-size: 16px;
        font-weight: 300;
      }
      p.count-down {
        font-size: 14px;
        font-weight: 300;
        padding: 30px 0;
      }
    }
  
    &.account-page {
      min-height: 100vh;
      img.svg-background {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top: -20px;
        opacity: 0.8;
        object-fit: cover;
      }
      .account-forms {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        text-align: center;
        padding: 0 50px;
        .registration-form{
          width:100%;
          max-width:650px;
        }
        .input-box {
          padding: 10px 5px 10px;
          label {
            color: $textGrey;
          }
          input,
          textarea {
            border-radius: 0;
            transition: outline 0.2s ease;
            border: none;
            border-bottom: 1px solid lightgrey;
            font-family: inherit;
            font-weight: 300;
            border-radius: 0;
  
            &:focus {
              border-bottom: 2px solid #f2dd64;
              outline: none;
            }
          }
        }
      }
    }
  
    &.client-dashboard-page {
      position: relative;
      min-height: 100vh;
      top: 20px;
      .tab {
        position: relative;
        width: 80%;
        min-width: 1000px;
        display: inline-block;
        .tabs {
          width: 100%;
          text-align: left;
          .tab-button {
            display: inline-block;
            padding: 4px 17px;
            -webkit-user-select: none;
            user-select: none;
            cursor: pointer;
            font-size: 14px;
            color: #545454;
            background-color: #f0f0f0;
            &.selected {
              background-color: #efd43d;
            }
          }
        }
        .tab-pages {
          width: 100%;
  
          .tab-page {
            display: none;
            text-align: left;
            height: auto;
            min-height: 500px;
            padding: 20px;
            border: 1px solid #eae9e9;
            box-shadow: 3px 2px 6px lightgrey;
            &.open {
              display: block;
            }
  
            .admin-table {
              position: relative;
              box-shadow: none;
              border-radius: 0px;
              margin-top: 30px;
              border: none;
              min-width: auto;
              min-height: 200px;
              .actions-header {
                display: none;
              }
              table {
                box-shadow: 0 0 4px lightgrey;
                tr,
                td,
                th {
                  border: none;
                }
              }
            }
            .settings-page {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              min-width: 800px;
              .user-info {
                position: relative;
                width: 50%;
                padding: 20px;
                border-right: 1px solid lightgrey;
                input:disabled,
                textarea:disabled {
                  border: 1px solid white;
                  background-color: white;
                  padding-left: 0;
                  text-indent: 0;
                  color: black;
                  font-weight: 400;
                  letter-spacing: 1px;
                }
                button.edit {
                  position: relative;
                  font-size: 13px;
                  border: none;
                  float: right;
                  padding: 6px 10px 6px 30px;
                  background-color: black;
                  color: white;
                  border-radius: 6px;
                  img {
                    filter: invert(1);
                    height: 15px;
                    position: absolute;
                    display: inline-block;
                    left: 9px;
                    top: 6px;
                  }
                }
              }
              .password {
                position: relative;
                width: 50%;
                padding: 20px;
              }
              .form {
                .form-title {
                  padding: 14px 0 8px;
                }
              }
              .input-box {
                padding: 0px;
                label {
                  font-size: 13px;
                }
                input {
                  border: 1px solid lightgrey;
                  font-size: 15px;
                  font-weight: 300;
                  padding: 4px 2px;
                  border-radius: 4px;
                }
              }
              .button-box {
                text-align: left;
                .button-default {
                  padding: 2px 29px;
                }
              }
            }
          }
        }
      }
    }
  
    .why-choose-us {
      position: relative;
      padding: 50px 0;
      background-color: #f6f6f6;
      .reason-card {
        padding: 20px;
        .icon {
          img {
            width: 30px;
          }
        }
        h5 {
          font-size: 13px;
          text-transform: uppercase;
          font-weight: 700;
          color: #2f302c;
          padding: 15px 0;
        }
        p {
          font-size: 11px;
          color: #7e7f7c;
          font-weight: 500;
        }
      }
    }
  
    .customer-reviews {
      .slider {
        position: relative;
        height: auto;
        overflow: hidden;
        .slick-slider {
          padding: 30px 0 50px;
  
          .slick-dots {
            bottom: -10px;
            ul {
              li {
                button {
                  color: grey;
                  width: 11px;
                  height: 11px;
                  background-color: grey;
                  border-radius: 50%;
                  &::before {
                    content: '';
                  }
                }
  
                &.slick-active {
                  button {
                    background-color: lightgrey;
                    &::before {
                      content: '';
                    }
                  }
                }
              }
            }
          }
        }
      }
      .customer-review {
        text-align: left;
        font-family: $fontFamily;
        .content{
          font-size: 15px;
          font-weight: 500;
          padding: 20px 50px 30px;
          .review-header{
            color: #484848;
            font-weight: 700;
            font-size: 15px;
            text-align: right;
            padding-bottom: 20px;
          }
          p{
            text-align: justify;
            font-weight: 300;
            font-size: 14px;
          }
        }
        
        .stars {
          margin: 15px 0 0;
          font-size: 26px;
          letter-spacing: 5px;
          color:$logoYellow;
          text-align: center;
        }
        .author {
          color: #484848;
          font-weight: 700;
          font-size: 14px;
          text-align: center;
        }
      }
    }
  
    @media only screen and (max-width: 768px) {
      top: 60px;
    }
  }

  .page-section {
    padding:50px 20px;
    .section-header{
      padding: 20px 0 50px;
      .main{
        @include section-main-header;
      }
      .sub{
        @include section-sub-header
      }
    }
  }

  .row {
    margin: 0;
    padding: 0;
    & > * {
      padding: 0;
      margin: 0;
    }
  }
}


a {
  text-decoration: none;
  color: $textBlack;
}

.form-loading {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  .form-header {
    
    &.header-center{
      text-align: center;
    }
    &.header-left{
      text-align: left;
    }
    .main{
      @include form-main-header()
    }
    .sub {
      @include form-sub-header()
    }
  }

  
  .input-box {
    width: 100%;
    position: relative;
    text-align: left;
    padding: 10px 0 0px;
    font-size: 0;
    &.box-inline {
      display: inline-block;
      width: auto;
    }
    label {
      font-size: 14px;
      color: $textGrey;
      .required {
        color: red;
        text-indent: 2px;
        display: inline-block;
      }
    }
    .input-image-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      .add-image {
        width: 100px;
        height: 100px;
        position: relative;
        top: -2px;
      }
    }
    input,
    select,
    textarea {
      padding: 7px 3px 1px;
      margin: 2px 0 0;
      font-size: 14px;
      width: 100%;
      border: none;
      border-bottom: 1px solid lightgrey;
      color: $textGrey;
      outline: none;
      text-indent: 2px;
      font-family: inherit;
      border-radius: 0;
    }

    input[type='file'] {
      padding: 7px 0;
      text-indent: 0;
      border: none;
      &::file-selector-button {
        border-radius: 6px;
        background-color: grey;
        color: white;
        border: none;
        font-size: 13px;
        cursor: pointer;
        padding: 2px 15px;
      }
    }

    textarea {
      min-height: 70px;
    }

    select {
      padding: 8px 3px;
      margin: 2px 0;
    }

    span.warning-message {
      visibility: hidden;
      display: inline-block;
      width: 100%;
      color: red;
      font-size: 11px;
      text-align: right;
      min-height: 16.5px;
      &.show {
        visibility: visible;
      }
    }

    &.disabled{
      input,textarea,select{
        background-color:lightgrey;
        color:grey;
        border:grey;
      }

      button{
        background-color:grey;
      }
    }
  }
}

.button-box {
  width: 100%;
  position: relative;
  padding: 20px 0 20px;
  text-align: center;
  button {
    background-color: black;
    color: white;
    border: none;
    text-transform: uppercase;
    letter-spacing: 2px;
    min-width: 156px;
    width: auto;
    font-weight: 600;
    padding: 8px 13px;
    height: auto;
    border-radius: 7px;
    font-size: 11px;
    &:disabled {
      background-color: lightgrey;
    }
    &.button-full-width {
      width: 100%;
    }
  
    &.button-default {
      min-width: 156px;
      width: auto;
    }
  }
  
}

.background-wrapper {
  position: relative;
  height: auto;
  top: 70px;
  padding: 50px 0 0;
  text-align: center;
}

.pageslist {
  ul {
    li {
      display: block;
      text-align: center;
    }
  }
}

#root {
  margin: 0 auto;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  height: 62px;
  width: 100%;
  background-color: white;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  transition: all 0.2s ease;
  padding: 0px calc((100vw - $contentMaxWidth) / 2);

  div.logo {
    height: 100%;

    a {
      display: inline-block;
      height: 100%;

      img {
        height: 42px;
        position: relative;
        top: 10px;
        transition: height 0.2s ease;
      }
    }
  }
  div.search-container {
    display: flex;
    align-items: center;
    top: 60px;
    justify-self: center;
    background-color: #ffffff;
    padding: 0 10px;
    border-radius: 5px;
    box-shadow: 0 0 23px #dcdcdc;
    align-self: center;
    div.search-bar {
      position: relative;
      img {
        width: 18px;
        height: auto;
        position: absolute;
        right: 0px;
        top: 8px;
      }
      input {
        position: relative;
        margin: 0;
        font-family: inherit;
        line-height: inherit;
        border: none;
        background-color: transparent;
        font-size: 13px;
        letter-spacing: 0.5px;
        padding: 7px 32px 6px 15px;
        outline: none;
        color: #919191;
        font-weight: 300;
        &::placeholder {
          color: #a1a1a1;
          font-size: 13px;
          letter-spacing: 0px;
          font-weight: 300;
          line-height: 1;
        }
      }
    }
    @media screen and (max-width:576px){
      position: absolute;
      width: 100%;
      text-align: center;
      display: block;
      box-shadow: 0 3px 3px #a9a9a9;
      
      .search-bar{
        #search-box{
          width:100%;
          font-size:17px;
          color:#555555;
        }
      }
    }
  }

  nav.header-menu {
    position: relative;
    height: 100%;
    display: flex;
    ul.nav-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      height: 100%;
      li {
        display: inline-block;
        padding: 0 7px;
        a {
          text-transform: uppercase;
          text-decoration: none;
          color: black;
          font-size: 12px;
          font-weight: 600;
          text-wrap:nowrap;
          &.selected {
            text-decoration: underline;
          }
        }
      }
    }

    &.mobile-active {
      padding-top: 100px;

      ul.nav-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        li.header-link {
          padding: 18px 0;
          a {
            font-size: 18px;
            padding: 0;
          }
          nav.nav-social {
            label {
              font-size: 15px;
            }
            ul {
              li {
                display: inline-block;
                margin: 0 20px;
                a {
                  text-transform: initial;
                  img {
                    width: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      position: relative;

      ul.nav-menu {
        padding: 20px 0;
        li {
          display: block;
          text-align: center;
          padding: 0;
          height: auto;
          a {
            font-size: 16px;
            display: inline-block;
            width: 100%;
            padding: 10px 0;
          }

          .cart {
            position: relative;
            display: inline-block;

            .cart-item-count {
              position: absolute;
              top: -7px;
              right: -7px;
              font-size: 15px;
              vertical-align: middle;
              font-weight: 600;
              display: flex;
              height: 22px;
              width: 22px;
              background: #ff7e07;
              color: white;
              text-align: center;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
            }

            img.cart-icon {
              width: 25px;
              transform: rotateY(180deg);
            }
          }
        }
      }
    }
  }

  div.icons {
    display: flex;
    justify-content: center;
    align-items: center;
    ul.icon-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        display: inline-block;
        padding: 0px 5px;
        .search-icon{
          img{
            width: 27px;
            position: relative;
            top: 2px;
            cursor:pointer;
          }
        }
        .cart {
          position: relative;

          .cart-item-count {
            position: absolute;
            top: -7px;
            right: -7px;
            font-size: 12px;
            vertical-align: middle;
            font-weight: 600;
            display: flex;
            height: 22px;
            width: 22px;
            background: #ff7e07;
            color: white;
            text-align: center;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
          }

          img.cart-icon {
            width: 32px;
            height: auto;
          }
        }
        
        .account {
          img.account-icon {
            width: 36px;
            height: auto;
            position: relative;
            top: 2px;
          }
        }
        .account-info {
          padding: 6px 0px;
          line-height: 1;
          cursor: pointer;
          -webkit-user-select: none;
          user-select: none;
          font-size: 14px;
          border-radius: 50%;
          border: 2px solid $mainYellow;
          width: 38px;
          height: 38px;
          overflow: hidden;
          background-color: black;
          color: $mainYellow;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          &.open {
            border: 2px dashed $mainYellow;
            .user-menu {
              display: block;
              user-select: none;
            }
          }
          .user-name {
            font-weight: 600;
            font-size: 13px;
            user-select: none;
          }
          .user-menu {
            position: absolute;
            top: 100%;
            right: 0;
            width: 20%;
            min-width: 210px;
            margin-top: 2px;
            background-color: whitesmoke;
            border-radius: 3px;
            text-align: center;
            white-space: nowrap;
            display: none;
            ul {
              font-weight: 600;
              font-size: 13px;
              li {
                display: block;
                padding: 6px 0;
                &:hover {
                  background-color: darken(whitesmoke, 10%);
                }
                a {
                  width: 100%;
                  display: inline-block;
                }
                &.user-name{
                    border-bottom: 1px solid #e0e0e0;
                }
              }
            }
          }
        }
      }
    }
  }

  &.home-page-header {
    background-color: transparent;
    box-shadow: none;
    display: flex;
  }

  &.dark {
    background-color: #000;
    color: white;
    position: fixed;
    left: 0;
    top: 0;
    transition: all 0.2s ease;
    .search-container {
      .search-bar {
        svg {
          fill: #c5c5c5;
        }
        input::placeholder {
          color: #c5c5c5;
        }
      }
    }
    nav.header-menu {
      display: flex;
      ul.nav-menu {
        li {
          a {
            color: white;
            font-weight: 400;
          }
        }
      }
    }
    div.icons {
      ul.icon-menu {
        li.header-link {
          .hamburger-menu {
            .line {
              background-color: $mainYellow;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    height: 60px;
    padding: 0;
    div.logo {
      height: 100%;
      float: left;

      a {
        img {
          height: 37px;
          top: 10px;
          left: -12px;
        }
      }
    }

    nav.header-menu {
      z-index: 1;
      height: 60px;
      ul {
        li {
          display: block;
          text-align: center;
          padding: 0;
          height: auto;
          a {
            font-size: 16px;
            display: inline-block;
            width: 100%;
            padding: 10px 0;
          }

          .cart {
            position: relative;
            display: inline-block;

            .cart-item-count {
              position: absolute;
              top: -7px;
              right: -7px;
              font-size: 12px;
              vertical-align: middle;
              font-weight: 600;
              display: flex;
              height: 22px;
              width: 22px;
              background: #ff7e07;
              color: white;
              text-align: center;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
            }

            img.cart-icon {
              width: 25px;
              transform: rotateY(180deg);
            }
          }
        }
      }
    }
    div.icons {
      ul.icon-menu {
        li {
          padding: 0px 7px;
        }
      }
      .hamburger-menu {
        position: relative;
        z-index: 12;
      }
    }
  }

  .mobile-menu-container {
    position: fixed;
    right: 0;
    top: 60px;
    width: 100%;
    background-color: white;
    height: calc(100vh - 60px);
    display: none;
    &.open {
      display: block;
    }

    .mobile-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      ul {
        li {
          font-size: 23px;
          padding: 8px 0;
          text-align: center;
          a {
            cursor: pointer;
            color:$textBlack;
          }
          .socials-container {
            label {
              font-size: 15px;
              color: #a4a4a4;
              font-weight: 300;
            }
            ul.nav-social {
              text-align: center;
              li {
                display: inline-block;
                a {
                  padding: 10px;
                  
                }
              }
            }
          }
        }
      }
    }
  }
}

footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  .footer-content{
    padding:40px 100px;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    .top {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: start;
      justify-content: space-evenly;
      padding: 0px 0 20px;
      nav {
        ul {
          width: 100%;
          li {
            text-align: left;
            &.list-header {
              font-weight: 800;
              font-size: 11px;
              margin-bottom: 7px;
              text-transform: uppercase;
            }
            a {
              font-size: 12px;
              display: inline-block;
              width: 100%;
              padding: 4px 0;
              color: #979797;
            }
          }
        }
        &.nav-social {
          li a img {
            width: 18px;
            position: relative;
            top: -1px;
            margin-right: 6px;
            opacity: 0.7;
          }
        }
        @media only screen and (min-width: 576px) {
          padding-left: 20px;
        }

        @media only screen and (max-width: 576px) {
          padding-left: 0px;
          ul{
            padding-left:0;
            margin-bottom:24px;
            li{
              text-align:center;
            }
          }
        }
      }
    }
  
    .bottom {
      position: relative;
      text-align: center;
      padding: 15px 0;
      font-size: 12px;
      color: #979797;
      display: flex;
      justify-content: space-between;
      ul.social-icons {
        margin-top:0;
        li {
          display: inline-block;
          img {
            width: 14px;
            margin: 0 10px;
          }
        }
      }
      @media screen and (max-width:576px){
        display: block;
        ul.social-icons{
          margin-top:13px;
        }
      }
    }
  }


  .whatsapp-link {
    position: fixed;
    height: 48px;
    width: 48px;
    bottom: 14px;
    right: 26px;
    padding: 7px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 4px solid #00c967;
    box-shadow: 0 0 10px lightgrey;
    a {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 40px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .top {
      padding: 40px 0;
      nav {
        margin-bottom: 10px;
        ul {
          width: 100%;
          padding-left: 25px;
          li {
            text-align: left;
            &.list-header {
              font-size: 13px;
            }
            a {
              font-size: 14px;
              display: inline-block;
              width: 100%;
              padding: 3px 0;
            }
          }
        }
        @media only screen and (max-width: 768px) {
          padding-left: 15px;
        }
      }
    }

    .bottom {
      position: relative;
      text-align: center;
      div.logo {
        position: relative;
        height: 50px;

        a {
          display: inline-block;
          height: 100%;

          img {
            height: 48px;
            position: relative;
            top: 3px;
          }
        }
      }
    }
  }
}


.loading {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $textBlack;
  font-size: 13px;
  font-weight: 600;
}

.hamburger-menu {
  cursor: pointer;
  .icon {
    width: 25px;
    .line {
      width: 100%;
      height: 3px;
      margin: 5px 0;
      background-color: #323232;
      transition: all 0.2s ease;
    }
  }
  &.open {
    .line:nth-child(1) {
      transform: translate(1px, 8px) rotate(45deg);
    }
    .line:nth-child(2) {
      opacity: 0;
    }
    .line:nth-child(3) {
      transform: translate(1px, -8px) rotate(315deg);
    }
  }
}

.categories-list-button{
  cursor: pointer;
  .icon {
    width: 22px;
    .line {
      position:relative;
      width: 100%;
      height: 2px;
      margin: 5px 0;
      background-color: #323232;
      transition: all 0.2s ease;
      &::before{
        content: "";
        position: absolute;
        left: -5px;
        top: 0;
        width: 2px;
        height: 2px;
        background-color: #323232;
        display: inline-block;
      }
    }
  }
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: center;
  padding: 0 50px;
  
  .input-box {
    padding: 10px 5px 10px;
    label {
      color: $textGrey;
    }
    input,
    textarea {
      border-radius: 0;
      transition: outline 0.2s ease;
      border: none;
      border-bottom: 1px solid lightgrey;
      font-family: inherit;
      font-weight: 300;
      border-radius: 0;

      &:focus {
        border-bottom: 2px solid #c7ab34;
        outline: none;
      }
    }
  }
  &.login-form,
  &.registration-form,
  &.forgot-password-form,
  &.reset-password-form {
    position: relative;
    background-color: white;
    box-shadow: 0 0 32px #dddddd;
    padding: 32px;
    max-width: 450px;
    width: 100%;
    display: flex;
    flex-flow: column;
    margin: 20px 0 20px;
    border-radius: 10px;
    overflow: hidden;

    form {
      text-align: center;
      margin: 25px auto;
      width: 100%;
    }

    #create-new {
      a {
        text-decoration: underline;
        color: blue;
      }
    }

    #forgot-password {
      text-align: right;
      a {
        font-size: 14px;
        color: black;
        display: block;
        font-weight: 300;
        text-decoration: underline;
        color: blue;
      }
    }
  }
}

.not-found-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 30px 50px;
  img {
    width: 159px;
    opacity: 0.7;
  }
  h3 {
    padding: 32px 0 15px;
    color: #9a9a9a;
    font-weight: 300;
    font-size: 23px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    a {
      font-weight: 400;
    }
  }
}
